<template>
  <div>
    <Pane />
    <div class="container">
      <Detail />

      <div class="center">
        <a-space>
          <a-button v-if="addMajorVisible" @click="addMajorUser" :disabled="okDisabled"
            :loading="majorLoading">发至专业负责人</a-button>

          <a-button v-else type="primary" :disabled="okDisabled" :loading="loading" @click="ok">发起审批</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>

        <CommentModal :visible="commentVisible" :title="commentTitle" @setVisible="setCommentVisible" @done="done" />
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import Detail from "./detail.vue";
import { allocate, setMajorUser } from "@/api/project";
import { mapMutations, mapGetters, mapState } from "vuex";
import request from "@/api/request";

import { check } from "./utils/tools";
import { division } from "./utils/validate";
import CommentModal from "@/components/comment-modal";

import { checkMajor } from "./utils/check-major";

const roleRatioList = [
  {
    value: "major_charger",
    ratio: 0.1,
  },
  {
    value: "design",
    ratio: 0.6,
  },
  {
    value: "recheck",
    ratio: 0.15,
  },
  {
    value: "audit",
    ratio: 0.1,
  },
  {
    value: "approval",
    ratio: 0.05,
  },
];

export default {
  name: "adjust-allocate",

  mixins: [watermark],

  components: {
    Detail,
    CommentModal,
  },

  data() {
    return {
      id: null, // 项目 id

      loading: false,

      commentVisible: false, // 审批意见弹窗
      commentTitle: "",
      storageValue: [], // 暂存准备提交的数据

      majorLoading: false,
    };
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.getDetail();
    }
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 提示语列表
    messageList() {
      return this.findDataDict("message");
    },
    // 角色类型列表
    roleTypeList() {
      return this.findDataDict("prjAllocateRole");
    },
    // 用户角色
    ...mapGetters("auth", ["isContractAdmin"]),

    // 阶段列表、分包列表、是否从项目详情页面过来
    ...mapState("adjustAllocate", ["list", "project"]),

    // 确认按钮是否禁用
    okDisabled() {
      // 所有的阶段都是不可配置的，就禁用
      let count = 0;
      this.list.forEach((element) => {
        if (!element.configurable) {
          count++;
        }
      });
      return this.list.length === count;
    },

    // 是否能添加专业负责人
    addMajorVisible() {
      let flag = false;
      this.list.forEach((phase) => {
        if (phase.configurable) {
          phase.majorGroupList?.forEach((element) => {
            element.list?.forEach((major) => {
              if (!major.allocatePersonId) {
                flag = true;
              }
              if (
                major.staffList.length === 0 ||
                !major.staffList[0].userName
              ) {
                flag = true;
              }
            });
          });

          if (phase.tec) {
            if (!phase.tec.allocatePersonId) {
              flag = true;
            }
            if (
              phase.tec.staffList.length === 0 ||
              !phase.tec.staffList[0].userName
            ) {
              flag = true;
            }
          }
        }
      });
      console.log("flag", flag);

      return flag;
    },
  },

  methods: {
    ...mapMutations("adjustAllocate", ["setList", "setProject"]),

    // 查询项目的分配情况
    getDetail() {
      request({
        url:
          "/project-service/progress/queryProjectAllocate/progress/" + this.id,
      }).then((res) => {
        if (res) {
          const { allocateCalcFormula, allocateCalcFactor } = res;
          const newList = [res].map((item) => {
            // 如果不是配过的，那肯定没有成本，需要自己构建
            const newCostList = res.subList.map((element) => ({
              major: element.major, // 这项分包关联的专业
              planAmount:
                allocateCalcFormula === "2022" &&
                typeof allocateCalcFactor === "number"
                  ? element.planAmount * (1 - allocateCalcFactor)
                  : element.planAmount,
            }));

            console.log("newCostList ", newCostList);

            // 项目服务
            let first = item.allocateList.find(
              (element) => element.type === "dept_service"
            );
            if (!first) {
              first = {
                type: "dept_service",
                allocateQuota: 4,
                disabled: true,
                collapsed: false,
                staffList: [
                  {
                    allocateUnit: "%",
                    allocateRatio: 100,
                  },
                ],
              };
            } else {
              first = {
                ...first,
                disabled: true,
                staffList: first.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              };
            }

            // 设计负责人
            let second = item.allocateList.find(
              (element) => element.type === "dept_charger"
            );
            if (!second) {
              second = {
                type: "dept_charger",
                allocateQuota: 6,
                disabled: true,
                collapsed: false,
                staffList: [
                  {
                    allocateUnit: "%",
                    allocateRatio: 100,
                  },
                ],
              };
            } else {
              second = {
                ...second,
                disabled: true,
                // staffList: second.allocateStaffList,
                staffList: second.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              };
            }
            // 技术经济
            let tec = item.allocateList.find(
              (element) => element.type === "tech_dept"
            );

            if (!tec) {
              if (item.allocateList.length === 0) {
                // 从来没配置过
                tec = {
                  // 技术经济
                  type: "tech_dept",
                  allocateQuota: 5,
                  collapsed: false,
                  staffList: [
                    ...this.roleTypeList.map((type) => ({
                      role: type.value,
                      roleRatio:
                        roleRatioList.find((item) => item.value === type.value)
                          .ratio * 100,
                      allocateUnit: "%",
                      allocateRatio: 100,
                    })),
                  ],
                };
              }
            } else {
              tec = {
                ...tec,
                allocateAmount: tec.allocateAmount
                  ? division(tec.allocateAmount)
                  : undefined,
                // staffList: tec.allocateStaffList,

                staffList: tec.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              };
            }

            let majorGroupList = [];
            item.allocateList
              .filter((element) => element.majorGroup)
              .forEach((element) => {
                const index = majorGroupList.findIndex(
                  (major) => major.majorGroup === element.majorGroup
                );

                if (index > -1) {
                  majorGroupList[index].list.push({
                    ...element,

                    staffList: element.allocateStaffList.map((el) => {
                      return {
                        ...el,
                        allocateRatio:
                          el.allocateUnit === "万" && el.allocateAmount
                            ? division(el.allocateAmount)
                            : el.allocateRatio,
                        allocateAmount: undefined,
                      };
                    }),
                  });
                } else {
                  majorGroupList.push({
                    majorGroup: element.majorGroup,
                    majorGroupQuota: element.majorGroupQuota,
                    list: [
                      {
                        ...element,

                        staffList: element.allocateStaffList.map((el) => {
                          return {
                            ...el,
                            allocateRatio:
                              el.allocateUnit === "万" && el.allocateAmount
                                ? division(el.allocateAmount)
                                : el.allocateRatio,
                            allocateAmount: undefined,
                          };
                        }),
                      },
                    ],
                  });
                }
              });

            if (majorGroupList.length === 0) {
              // 如果技术3有同种类型的分包，把他们合并
              let arr = [];
              newCostList.forEach((cost) => {
                const index = arr.findIndex((i) => i.major === cost.major);
                if (index > -1) {
                  arr[index] = {
                    ...arr[index],
                    planAmount: cost.planAmount + arr[index].planAmount,
                    // quota: cost.quota + arr[index].quota,
                  };
                } else {
                  arr.push({
                    ...cost,
                  });
                }
              });

              if (arr.length > 0) {
                majorGroupList = [
                  {
                    majorGroup: "子项1",
                    majorGroupQuota: 100,
                    list: arr.map((element) => {
                      return {
                        allocateQuota: parseInt(100 / arr.length),
                        type: element.major,
                        collapsed: false,
                        staffList: [
                          ...this.roleTypeList.map((type) => ({
                            role: type.value,
                            roleRatio:
                              roleRatioList.find(
                                (item) => item.value === type.value
                              ).ratio * 100,
                            allocateUnit: "%",
                            allocateRatio: 100,
                          })),
                        ],
                      };
                    }),
                  },
                ];
              }
            }

            // 施工配合和其他
            let otherList = [];
            const other1 = item.allocateList.find(
              (element) => element.type === "sgph"
            );
            const other2 = item.allocateList.find(
              (element) => element.type === "sgphqt"
            );
            if (other1) {
              otherList.push({
                ...other1,
                // staffList: other1.allocateStaffList,

                staffList: other1.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              });
            }
            if (other2) {
              otherList.push({
                ...other2,
                // staffList: other2.allocateStaffList,

                staffList: other2.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              });
            }

            let configurable = false;
            let configMessage = "";

            console.log("phaseProgressStatus", item.phaseProgressStatus);
            console.log("staffConfigureStatus", item.staffConfigureStatus);

            // 这个阶段的进度审核通过后，并且分配审核不处于审核中，审核通过，就可以配置
            if (item.phaseProgressStatus === "approved") {
              if (
                "reviewing approved".indexOf(item.staffConfigureStatus) === -1
              ) {
                configurable = true;
                configMessage = "";
              } else {
                configMessage = "产值分配审批尚未通过，";
              }
            } else {
              configMessage = "阶段进度审批尚未通过，";
            }
            // 或者当前登录用户是合同管理员，只要不在审核中，就可以改
            if (
              this.isContractAdmin &&
              "reviewing".indexOf(item.staffConfigureStatus) === -1
            ) {
              configurable = true;
              configMessage = "";
            }

            // 如果是往年的项目，不可配置
            if (item.legacy === "1") {
              configurable = false;
              configMessage = "历史项目";
            }

            console.log("configurable", configurable, configMessage);
            return {
              ...item,

              configurable,
              configMessage,

              // 每个阶段下有数据就拆分成几个字段，没有就自己构建
              // 前两个
              twoList: [first, second],

              tec,

              // 所有的子项，包括里面的专业
              majorGroupList,
              // 其他类型
              otherList,

              // 文件
              attachments: Array.isArray(item.fileList) ? item.fileList : [],

              // 如果有技术3的分包
              subCostQuotaList: newCostList,
            };
          });

          // 设置全局状态
          this.setList(newList);
        }
      });
    },

    ok() {
      const message = check(this.list);
      if (message) {
        this.$message.error(message);
        return;
      }

      const params = this.list.filter((item) => item.configurable);
      if (params.length === 0) {
        this.$message.error("所有阶段暂时无法配置！");
        return;
      }

      this.commentVisible = true;
      this.commentTitle = this.messageList.find(
        (item) => item.value === "allocate"
      ).name;
      this.storageValue = params;
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
    },

    done(comment) {
      this.allocateRequest(this.storageValue, comment);
    },

    allocateRequest(params, comment) {
      let arr = [];
      params.forEach((item) => {
        let attachments = item.attachments.map((item) => item.id);
        let allocateList = [];
        let twoList = item.twoList.map((element) => {
          return {
            ...element,
            allocateStaffList: element.staffList.map((staff) => {
              return {
                ...staff,
                allocateRatio:
                  staff.allocateUnit === "万"
                    ? staff.allocateRatio * 10000
                    : staff.allocateRatio,
              };
            }),
          };
        });

        allocateList.push(...twoList);

        if (item.tec) {
          let tec = {
            ...item.tec,
            allocateAmount: item.tec.allocateAmount
              ? item.tec.allocateAmount * 10000
              : undefined,

            attachments: item.tec.attachmentsList
              ? item.tec.attachmentsList.map((item) => item.id).join(",")
              : null,

            allocateStaffList: item.tec.staffList.map((staff) => {
              return {
                ...staff,
                allocateRatio:
                  staff.allocateUnit === "万"
                    ? staff.allocateRatio * 10000
                    : staff.allocateRatio,
              };
            }),
          };
          allocateList.push(tec);
        }

        let majorList = [];
        item.majorGroupList.forEach((major) => {
          major.list.forEach((el) => {
            majorList.push({
              ...el,
              attachments: el.attachmentsList
                ? el.attachmentsList.map((item) => item.id).join(",")
                : null,

              majorGroup: major.majorGroup,
              majorGroupQuota: major.majorGroupQuota,

              allocateStaffList: el.staffList.map((staff) => {
                return {
                  ...staff,
                  allocateRatio:
                    staff.allocateUnit === "万"
                      ? staff.allocateRatio * 10000
                      : staff.allocateRatio,
                };
              }),
            });
          });
        });

        allocateList.push(...majorList);

        let otherList = item.otherList.map((other) => {
          return {
            ...other,
            allocateStaffList: other.staffList.map((staff) => {
              return {
                ...staff,
                allocateRatio:
                  staff.allocateUnit === "万"
                    ? staff.allocateRatio * 10000
                    : staff.allocateRatio,
              };
            }),
          };
        });
        allocateList.push(...otherList);

        arr.push({
          ...item,

          subCostQuotaList: Array.isArray(item.subCostQuotaList)
            ? item.subCostQuotaList.map((cost) => ({
                ...cost,
                quota: cost.quota ? cost.quota : 0,
              }))
            : undefined,

          fileIdList: attachments,
          allocateList,

          twoList: undefined, // 前两个
          tec: undefined, // 技术经济
          majorGroupList: undefined,
        });
      });

      console.log("final data", arr);
      console.log("===============");

      this.loading = true;
      allocate({
        projectId: arr[0].projectId,
        progressList: arr,
        comment,
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 添加专业负责人
    addMajorUser() {
      // 有阶段可配置，并且有专业的负责人id为 null
      let message = "";
      let isOK = true;
      const list = this.list.filter((item) => item.configurable);
      list.forEach((item) => {
        item.majorGroupList?.forEach((element) => {
          element.list?.forEach((major) => {
            if (!major.allocatePersonId) {
              isOK = false;
              message =
                "请选择" +
                item.phaseName +
                "阶段" +
                element.majorGroup +
                "的专业负责人";
            }
          });
        });

        if (item.tec && !item.tec.allocatePersonId) {
          isOK = false;
          message = "请选择" + item.phaseName + "阶段技术经济的专业负责人";
        }
      });

      if (isOK) {
        const msg = checkMajor(this.list);
        if (msg) {
          this.$message.error(msg);
          return;
        }

        this.majorLoading = true;
        let arr = [];
        list.forEach((item) => {
          let attachments = item.attachments.map((item) => item.id);
          let allocateList = [];
          let twoList = item.twoList.map((element) => {
            return {
              ...element,
              allocateStaffList: element.staffList?.map((staff) => {
                return {
                  ...staff,
                  allocateRatio:
                    staff.allocateUnit === "万"
                      ? staff.allocateRatio * 10000
                      : staff.allocateRatio,
                };
              }),
            };
          });

          allocateList.push(...twoList);

          if (item.tec) {
            let tec = {
              ...item.tec,
              allocateAmount: item.tec.allocateAmount
                ? item.tec.allocateAmount * 10000
                : undefined,
              allocateStaffList: item.tec.staffList?.map((staff) => {
                return {
                  ...staff,
                  allocateRatio:
                    staff.allocateUnit === "万"
                      ? staff.allocateRatio * 10000
                      : staff.allocateRatio,
                };
              }),
            };
            allocateList.push(tec);
          }

          let majorList = [];
          item.majorGroupList.forEach((major) => {
            major.list?.forEach((el) => {
              majorList.push({
                ...el,
                majorGroup: major.majorGroup,
                majorGroupQuota: major.majorGroupQuota,
                allocateStaffList: el.staffList?.map((staff) => {
                  return {
                    ...staff,
                    allocateRatio:
                      staff.allocateUnit === "万"
                        ? staff.allocateRatio * 10000
                        : staff.allocateRatio,
                  };
                }),
              });
            });
          });

          allocateList.push(...majorList);

          let otherList = item.otherList.map((other) => {
            return {
              ...other,
              allocateStaffList: other.staffList?.map((staff) => {
                return {
                  ...staff,
                  allocateRatio:
                    staff.allocateUnit === "万"
                      ? staff.allocateRatio * 10000
                      : staff.allocateRatio,
                };
              }),
            };
          });
          allocateList.push(...otherList);

          arr.push({
            ...item,

            subCostQuotaList: Array.isArray(item.subCostQuotaList)
              ? item.subCostQuotaList.map((cost) => ({
                  ...cost,
                  quota: cost.quota ? cost.quota : 0,
                }))
              : undefined,

            fileIdList: attachments,
            allocateList,

            // 重置数据
            twoList: undefined,
            tec: undefined,
            majorGroupList: undefined,
          });
        });

        setMajorUser({
          projectId: arr[0].projectId,
          progressList: arr,
        })
          .then(() => {})
          .finally(() => {
            this.majorLoading = false;
          });
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 12px 12px 12px 0;
  background-color: #fff;
}

.center {
  padding: 48px 0;
}
</style>