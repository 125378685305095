<template>
  <div class="item">
    <a-row>
      <a-col :span="isMajor ? 7 : 6">
        <div class="header left">
          <DataDictFinder dictType="allocateType" :dictValue="item.type" />
          <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
          <DataDictFinder dictType="sgphlx" :dictValue="item.type" />
          <span v-if="!isMajor"> 分配数值（</span>
          <span v-else> 专业可分配数值（</span>
          <Money :money="money" />
          <span>万元）</span>
        </div>

        <!-- 如果当前专业，在分包里面有关联项，说明这个专业有成本需要扣除，那么选人分可分配数值的时候，所有的人的可分配数值 = 这个专业的可分配数值 - 这个专业的成本 -->
        <div v-if="showCost" style="margin-bottom: 8px" class="cost">
          <template v-if="!isEditingCost">
            <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
            <span>专业分包：</span>（
            <span> {{ costRatio }}% </span>
            <span style="padding: 0 4px"> / </span>
            <Money :money="cost" />
            <span>万元</span>
            ）
            <a-icon
              type="edit"
              class="edit"
              style="margin-right: 4px"
              @click="isEditingCost = true"
            />
          </template>
          <template v-else>
            <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
            <span>专业分包：</span>
            <a-input-number
              v-model="item.js3CostQuota"
              :formatter="$ratioFormatter"
              :min="0"
              :max="100"
              style="width: 64px; margin-right: 8px"
              size="small"
            />
            <Money :money="cost" />
            <span>万元</span>
            <a-icon
              type="check"
              class="edit"
              @click="isEditingCost = false"
              style="margin-left: 8px"
            />
          </template>
        </div>

        <div class="ratio-title">
          <span>
            <DataDictFinder dictType="allocateType" :dictValue="item.type" />
            <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
            <DataDictFinder dictType="sgphlx" :dictValue="item.type" />
          </span>

          <!-- 技术经济使用金额输入框或者比例选择器 -->
          <template v-if="isTec">
            <a-dropdown
              v-if="item.allocateQuota"
              :disabled="!phase.configurable"
            >
              <a-menu slot="overlay" @click="handleQuotaClick">
                <a-menu-item key="5"> 5% </a-menu-item>
                <a-menu-item key="10">10% </a-menu-item>
                <a-menu-item key="20">20% </a-menu-item>
              </a-menu>
              <a-button :disabled="item.disabled">
                {{ item.allocateQuota }}%
              </a-button>
            </a-dropdown>
            <a-input-number
              v-else
              :min="0"
              v-model="item.allocateAmount"
              style="width: 80px"
              :formatter="formatter2"
              :disabled="item.disabled || !phase.configurable"
            />

            <a-button
              :disabled="item.disabled || !phase.configurable"
              style="margin-left: 4px"
              @click="changeEditType"
              icon="edit"
            />
            <a-button
              :disabled="item.disabled || !phase.configurable"
              style="margin-left: 4px; margin-right: 8px"
              @click="removeTec"
              icon="delete"
              type="danger"
            />
          </template>
          <template v-else>
            <a-input-number
              :min="0"
              :max="100"
              v-model="item.allocateQuota"
              :formatter="$ratioFormatter"
              style="width: 80px"
              :disabled="item.disabled || !phase.configurable"
            />
            <!-- 有分包成本的专业是不可以删除的 -->
            <!-- v-if="!showCost" -->
            <a-button
              style="margin-left: 4px; margin-right: 8px"
              @click="remove"
              icon="delete"
              type="danger"
              :disabled="item.disabled || !phase.configurable"
            />
          </template>
        </div>
      </a-col>
      <a-col :span="isMajor ? 17 : 18" v-if="item.staffList.length > 0">
        <div class="header between">
          <div class="left">
            <div>参与分配人员</div>

            <a-tooltip
              title="增加审定"
              v-if="
                item.staffList[0].role &&
                item.staffList.filter((obj) => obj.role === 'approval')
                  .length === 0 &&
                phase.configurable
              "
            >
              <a-icon
                type="plus-circle"
                style="color: #666; cursor: pointer; margin-left: 4px"
                @click="addRole('approval')"
              />
            </a-tooltip>
          </div>

          <div
            v-if="item.collapsed"
            @click="
              item.collapsed = !item.collapsed;
              $forceUpdate();
            "
            style="color: #999; font-size: 12px; cursor: pointer"
          >
            <span style="padding-right: 4px">展开</span>
            <a-icon type="down" />
          </div>
          <div
            v-else
            @click="
              item.collapsed = !item.collapsed;
              $forceUpdate();
            "
            style="color: #999; font-size: 12px; cursor: pointer"
          >
            <span style="padding-right: 4px">折叠</span>
            <a-icon type="up" />
          </div>
        </div>

        <div v-if="isMajor || isTec" style="padding-bottom: 8px">
          <div v-if="showCost" style="margin-bottom: 8px" class="cost">
            &nbsp;
          </div>
          <EmployeeSelector
            @change="onSelectMajor"
            :disabled="!phase.configurable"
          >
            <a-button type="primary" :disabled="!phase.configurable">
              <div class="left" v-if="item.allocatePersonId">
                <span>专业负责人：{{ item.allocatePersonName }}</span>
              </div>
              <div class="left" v-else>请选择专业负责人</div>
            </a-button>
          </EmployeeSelector>
        </div>

        <div v-if="isMajor || isTec">
          <div v-if="item.staffList[0] && item.staffList[0].userName">
            <div v-show="!item.collapsed">
              <div v-if="item.staffList[0].role">
                <div v-for="(role, index) in roleList" :key="index">
                  <div class="left" style="margin-bottom: 8px; font-size: 14px">
                    <DataDictFinder
                      dictType="prjAllocateRole"
                      :dictValue="role.role"
                    />

                    <span>
                      （{{ role.roleRatio }}%
                      {{ " / " }}
                      <Money :money="surplus * (role.roleRatio / 100)" />
                      万元 ）
                    </span>
                    <a-icon
                      type="delete"
                      style="color: #666; cursor: pointer"
                      v-if="
                        role.role === 'approval' &&
                        roleFilter(role.role, item.staffList).length > 0 &&
                        phase.configurable
                      "
                      @click="removeRole(role.role)"
                    />
                  </div>

                  <div
                    style="
                      border: 1px solid #e0e0e0;
                      background: #f9f9f9;
                      padding: 12px;
                      margin-bottom: 8px;
                    "
                  >
                    <div
                      v-if="roleFilter(role.role, item.staffList).length > 0"
                    >
                      <StaffItem
                        v-for="(element, idx) in roleFilter(
                          role.role,
                          item.staffList
                        )"
                        :key="idx"
                        :money="surplus * (role.roleRatio / 100)"
                        :phase="phase"
                        :phaseIndex="phaseIndex"
                        :phaseItem="item"
                        :phaseItemIndex="index"
                        :majorGroup="majorGroup"
                        :role="role.role"
                        :staffItem="element"
                        :index="idx"
                      />
                    </div>
                    <div v-else>暂无数据</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <StaffItem
                  v-for="(element, idx) in item.staffList"
                  :key="idx"
                  :money="surplus"
                  :phase="phase"
                  :phaseIndex="phaseIndex"
                  :phaseItem="item"
                  :phaseItemIndex="index"
                  :majorGroup="majorGroup"
                  :staffItem="element"
                  :index="idx"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-show="!item.collapsed">
            <div v-if="item.staffList[0].role">
              <div v-for="(role, index) in roleList" :key="index">
                <div class="left" style="margin-bottom: 8px; font-size: 14px">
                  <DataDictFinder
                    dictType="prjAllocateRole"
                    :dictValue="role.role"
                  />

                  <span>
                    （{{ role.roleRatio }}%
                    {{ " / " }}
                    <Money :money="surplus * (role.roleRatio / 100)" />
                    万元 ）
                  </span>
                  <a-icon
                    type="delete"
                    style="color: #666; cursor: pointer"
                    v-if="
                      role.role === 'approval' &&
                      roleFilter(role.role, item.staffList).length > 0 &&
                      phase.configurable
                    "
                    @click="removeRole(role.role)"
                  />
                </div>

                <div
                  style="
                    border: 1px solid #e0e0e0;
                    background: #f9f9f9;
                    padding: 12px;
                    margin-bottom: 8px;
                  "
                >
                  <div v-if="roleFilter(role.role, item.staffList).length > 0">
                    <StaffItem
                      v-for="(element, idx) in roleFilter(
                        role.role,
                        item.staffList
                      )"
                      :key="idx"
                      :money="surplus * (role.roleRatio / 100)"
                      :phase="phase"
                      :phaseIndex="phaseIndex"
                      :phaseItem="item"
                      :phaseItemIndex="index"
                      :majorGroup="majorGroup"
                      :role="role.role"
                      :staffItem="element"
                      :index="idx"
                    />
                  </div>
                  <div v-else>暂无数据</div>
                </div>
              </div>
            </div>
            <div v-else>
              <StaffItem
                v-for="(element, idx) in item.staffList"
                :key="idx"
                :money="surplus"
                :phase="phase"
                :phaseIndex="phaseIndex"
                :phaseItem="item"
                :phaseItemIndex="index"
                :majorGroup="majorGroup"
                :staffItem="element"
                :index="idx"
              />
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <template v-if="isMajor || isTec">
      <div class="file-title">附件</div>
      <div class="link-list">
        <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
          <div
            class="link"
            v-for="item in Array.isArray(item.attachmentsList)
              ? item.attachmentsList
              : []"
            :key="item.id"
          >
            <a-checkbox :value="item.id" :disabled="!phase.configurable">
              <a target="_blank" download :href="item.completePath">{{
                item.name
              }}</a>
            </a-checkbox>
          </div>
        </a-checkbox-group>

        <div class="control-bar" v-if="phase.configurable">
          <FileUpload @uploaded="uploaded">
            <div class="control">
              <a-icon type="upload" />
            </div>
          </FileUpload>

          <div class="control" @click="deleteFile">
            <a-icon type="delete" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import StaffItem from "./staff-item.vue";
import EmployeeSelector from "@/components/employee-selector";
import { mapGetters } from "vuex";
import FileUpload from "@/components/file-upload";
export default {
  props: {
    // 所属阶段
    phase: {
      type: Object,
      default: () => ({}),
    },
    phaseIndex: {
      type: Number,
    },
    // 当前节点数据
    item: {
      type: Object,
      default: () => ({}),
    },
    // 子阶段循环渲染时的索引
    index: {
      type: Number,
      default: 0,
    },

    // 当前专业所属的子项，如果当前是个子阶段，那就为空字符串
    majorGroup: {
      type: String,
      default: "",
    },
    majorGroupQuota: {
      type: Number,
      default: 0,
    },

    // 当前节点金额
    money: {
      type: Number,
      default: 0,
    },

    // 数据来源，可能是 otherList，也可能是子项列表
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isEditingCost: false,

      selectedFileList: [],
    };
  },

  components: {
    StaffItem,
    EmployeeSelector,
    FileUpload,
  },

  computed: {
    roleList() {
      const roles = [
        ...new Set(this.item.staffList.map((staff) => staff.role)),
      ];

      return roles.map((r) => {
        const roleRatio = this.item.staffList.find(
          (staff) => staff.role === r
        ).roleRatio;
        return {
          role: r,
          roleRatio,
        };
      });
    },

    ...mapGetters("setting", ["findDataDict"]),

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },
    // 其他类型
    otherTypeList() {
      return this.findDataDict("sgphlx");
    },

    isTec() {
      return this.item.type === "tech_dept";
    },
    // 当前阶段是否为专业
    isMajor() {
      return (
        this.majorTypes.findIndex(
          (element) => element.value === this.item.type
        ) > -1
      );
    },
    // 当前阶段是否为其他
    isOther() {
      return (
        this.otherTypeList.findIndex(
          (element) => element.value === this.item.type
        ) > -1
      );
    },

    // 有没有技术经济
    hasTec() {
      let tec;
      this.phase.allocateList.forEach((element) => {
        if (element.type === "tech_dept") {
          tec = element;
        }
      });

      return !!tec;
    },

    // 专业剩余占比
    majorRatio() {
      // 技术经济
      let tec;
      this.phase.allocateList.forEach((element) => {
        if (element.type === "tech_dept") {
          tec = element;
        }
      });

      // 有技术经济
      if (tec) {
        return this.surplusMoney - this.surplusMoney * tec.allocateQuota * 0.01;
      } else {
        return this.surplusMoney;
      }
    },

    // 是否显示技术3分包成本
    showCost() {
      return (
        this.phase.subCostQuotaList.findIndex(
          (item) => item.major === this.item.type
        ) > -1
      );
    },
    // 算出当前专业有多少成本
    cost() {
      const sub = this.phase.subCostQuotaList.find(
        (item) => item.major === this.item.type
      );
      if (sub) {
        // 可能有重复的专业，所有需要累加

        let money = 0;

        this.phase.subCostQuotaList.forEach((item) => {
          if (item.major === this.item.type) {
            money += item.planAmount;
          }
        });

        // 这项专业分包的金额 * 这项专业在这个阶段下比重 * 这个子项在这个阶段下的比重（因为可能出现多个子项，都有这个专业）
        // 其他子项没有这项分包专业（可以删除），所以需要把当前有这个分包的成本设置为占子项的100%

        // 专业分包的金额根据用户自己设定的比例来计算
        // return money * this.majorGroupQuota * 0.01;
        return money * this.costRatio * 0.01;
      } else {
        return 0;
      }
    },
    costRatio() {
      return typeof this.item.js3CostQuota === "number"
        ? this.item.js3CostQuota
        : 0;
    },
    // 这个专业下能给人分的钱 = 这个专业的钱 - 成本
    surplus() {
      return this.money - this.cost;
    },

    // 角色类型列表
    roleTypeList() {
      return this.findDataDict("prjAllocateRole");
    },
  },

  methods: {
    formatter2(value) {
      return value + "万";
    },

    // 选择比例而不是输入比例
    handleQuotaClick(v) {
      console.log("v", v);
      this.item.allocateQuota = Number(v.key);
    },
    // 切换输入方式：比例或直接输入金额
    changeEditType() {
      if (this.item.allocateQuota) {
        this.item.allocateQuota = null;
      } else {
        this.item.allocateQuota = 5;
        this.item.allocateAmount = null;
      }
    },

    // 角色过滤
    roleFilter(role, list) {
      return list.filter((item) => item.role === role);
    },

    removeRole(role) {
      this.item.staffList.forEach((staff) => {
        if (staff.role === "audit") {
          staff.roleRatio = 15;
        }
      });

      this.item.staffList = [
        ...this.item.staffList.filter((item) => item.role !== role),
      ];
    },

    addRole(role) {
      this.item.staffList.forEach((staff) => {
        if (staff.role === "audit") {
          staff.roleRatio = 10;
        }
      });

      this.item.staffList = [
        ...this.item.staffList,
        {
          role,
          roleRatio: 5,
          allocateUnit: "%",
          allocateRatio: 100,
        },
      ];
    },
    // 技术经济
    removeTec() {
      this.$emit("removeTec");
    },

    remove() {
      this.list.splice(this.index, 1);

      // if (this.list.length > 1) {
      //   this.list.splice(this.index, 1);
      // } else {
      //   const typeValue = this.list[this.index].type;
      //   if (typeValue === "sgphqt" || typeValue === "sgph") {
      //     this.list.splice(this.index, 1);
      //   } else {
      //     this.$message.error("至少存在一项！");
      //   }
      // }
    },

    // 增加子项
    addMajorGroup() {
      let newMajorGroup = {
        majorGroup: `子项${this.phase.majorGroupList.length + 1}`,
        majorGroupQuota: 100,
      };
      this.phase.majorGroupList.push(newMajorGroup);

      // 找到所有的专业，重新塞值进去
      const arr = [];
      this.phase.allocateList.forEach((allocate) => {
        const index = this.majorTypes.findIndex(
          (element) => element.value === allocate.type
        );
        if (index > -1) {
          if (arr.findIndex((item) => item.type === allocate.type) > -1) {
            console.log("已经找到这个专业就不用添加了");
          } else {
            arr.push(JSON.parse(JSON.stringify(allocate)));
          }
        }

        // 其他类型不需要添加到子项中
      });

      this.phase.allocateList.push(
        ...arr.map((item) => {
          return {
            ...item,
            ...newMajorGroup,
          };
        })
      );
    },

    onSelectMajor(arr) {
      if (arr.length === 0) {
        return;
      }
      this.item.allocatePersonId = arr[0].userId;
      this.item.allocatePersonName = arr[0].name;
      this.$forceUpdate();
    },

    uploaded(files) {
      this.item.attachmentsList = [
        ...(this.item.attachmentsList ? this.item.attachmentsList : []),
        ...files,
        // ...files.map((item) => {
        //   return {
        //     id: item.id,
        //     name: item.name,
        //     completePath: item.completePath,
        //   };
        // }),
      ];
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.item.attachmentsList.findIndex(
          (file) => file.id === item
        );
        if (index > -1) {
          this.item.attachmentsList.splice(index, 1);
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.item {
  margin-bottom: 12px;
  background-color: #fff;
  padding: 12px;
}
.item:last-child {
  margin-bottom: 0;
}

.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 12px;
}
.ratio-title {
  display: flex;
  align-items: center;
}
.ratio-title > span {
  width: 120px;
  height: 32px;
  font-size: 13px;
  background: #1890ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.employee {
  margin-bottom: 12px;
}
.employee:last-child {
  margin-bottom: 0;
}

.link-list {
  background-color: #f3f3f3;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  min-height: 66px;

  .link {
    margin-bottom: 4px;
  }

  .control-bar {
    position: absolute;
    top: 0;
    right: 0;

    .control {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}

.small-title {
  font-weight: bold;
  margin-bottom: 16px;
}

.cost {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.file-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
  margin-bottom: 8px;
}
</style>