import { hasEmployee, validateMoney, validateRoleMoney } from './validate'
const allocateTypeList = [{
    name: '项目服务',
    value: 'dept_service',
}, {
    name: '设计负责人',
    value: 'dept_charger',
}, {
    name: '技术经济',
    value: 'tech_dept',
}]

// 获取人员列表中每个人的钱，返回总额
function getStaffMoney(allMoney, staffList) {
    let money = 0;
    staffList.forEach(staff => {
        if (staff.allocateUnit === '%') {
            money += allMoney * staff.allocateRatio * 0.01
        } else {
            money += staff.allocateRatio * 10000
        }
    })
    return money;
}

// 校验当前项目下阶段列表中有没有满足条件
export function check(list) {

    let message = '';



    checkLoop: for (let i = 0; i < list.length; i++) {
        let phase = list[i];

        // 如果当前阶段不可配置，跳过校验
        if (!phase.configurable) {
            continue;
        }

        // 1. 当前阶段是否上传文件
        if (phase.attachments.length === 0) {
            message = `${phase.phaseName}阶段各专业分配文件未上传！`
            break;
        }

        if (Array.isArray(phase.subCostQuotaList) && phase.subCostQuotaList.length > 0) {


            // 校验这个分包关联的专业用户有没有填，如果有部分子项没选择带分包的专业，所有带分包的专业比例 js3CostQuota 必须为 100%

            for (let x = 0; x < phase.subCostQuotaList.length; x++) {
                const costObj = phase.subCostQuotaList[x];

                if (Array.isArray(phase.majorGroupList) && phase.majorGroupList.length > 0) {

                    let costRatio = 0;

                    for (let y = 0; y < phase.majorGroupList.length; y++) {
                        const groupObj = phase.majorGroupList[y];

                        groupObj.list.forEach(groupItem => {
                            if (groupItem.type === costObj.major && groupItem.js3CostQuota) {
                                costRatio += groupItem.js3CostQuota;
                            }
                        })
                    }

                    console.log('costRatio', costRatio)
                    // 如果这项技术3分包在子项的专业列表中不存在，直接报错
                    if (Math.abs(costRatio - 100) > 0.000001) {
                        message = `${phase.phaseName}阶段专业分包成本比例不等于100%`
                        break checkLoop;
                    }
                } else {
                    message = `${phase.phaseName}阶段专业分包成本未承担`
                    break checkLoop;
                }

            }

        }


        // 校验前两个：项目服务和设计负责人
        for (let j = 0; j < phase.twoList.length; j++) {
            const obj = phase.twoList[j];
            const typeName = allocateTypeList.find(item => item.value === obj.type).name
            if (!hasEmployee(obj.staffList)) {
                message = `${phase.phaseName}阶段-${typeName}-参与分配人员未选择！`
                break checkLoop;
            }
            const amount = phase.quota * obj.allocateQuota * 0.01
            if (!validateMoney(amount, obj.staffList)) {
                message = `${phase.phaseName}阶段-${typeName}-参与分配人员总金额不正确！`
                break checkLoop;
            }
        }


        // 算出剩余占比
        let ratio = 0; // 总比例
        phase.twoList.forEach((item) => {
            ratio += item.allocateQuota;
        });
        const surplusMoney = phase.quota * (100 - ratio) * 0.01;
        let tecMoney = 0;

        // 校验技术经济 占比不能超过100，小于等于0
        if (phase.tec) {

            if (phase.tec.allocateAmount) {

                tecMoney = phase.tec.allocateAmount * 10000 // 技术经济的钱

                if (!hasEmployee(phase.tec.staffList)) {
                    message = `${phase.phaseName}阶段-技术经济-参与分配人员未选择！`
                    break checkLoop;
                }

                // 将所有的角色过滤出来，每种角色下的人员金额需要符合要求
                const msg = validateRoleMoney(tecMoney, phase.tec.staffList)
                if (msg) {
                    message = `${phase.phaseName}阶段-技术经济-${msg}-参与分配人员总金额不正确！`
                    break checkLoop;
                }

            } else {

                if (phase.tec.allocateQuota > 100 || phase.tec.allocateQuota <= 0) {
                    message = `${phase.phaseName}阶段-技术经济-金额占比不正确！`
                    break checkLoop;
                }

                tecMoney = surplusMoney * phase.tec.allocateQuota * 0.01 // 技术经济的钱

                if (!hasEmployee(phase.tec.staffList)) {
                    message = `${phase.phaseName}阶段-技术经济-参与分配人员未选择！`
                    break checkLoop;
                }

                // 将所有的角色过滤出来，每种角色下的人员金额需要符合要求
                const msg = validateRoleMoney(tecMoney, phase.tec.staffList)
                if (msg) {
                    message = `${phase.phaseName}阶段-技术经济-${msg}-参与分配人员总金额不正确！`
                    break checkLoop;
                }

            }



        }

        // 专业剩余占比的钱 = 剩余的钱 - 技术经济的钱
        let AllMajorMoney = surplusMoney - tecMoney

        // 校验子项、其他的比例之和是否满足100
        let ratio1 = 0;
        if (Array.isArray(phase.majorGroupList)) {
            phase.majorGroupList.forEach(item => {
                ratio1 += item.majorGroupQuota
            })
        }
        if (Array.isArray(phase.otherList)) {
            phase.otherList.forEach(item => {
                ratio1 += item.allocateQuota
            })
        }

        if (Math.abs(100 - ratio1) > 0.000001) {
            message = `${phase.phaseName}阶段-所有子项-金额占比不正确！`
            break checkLoop;
        }


        // 校验所有子项中每个子项的专业之和是否为100，以及每个专业下分配的人员的钱是否等于这个专业的金额
        if (Array.isArray(phase.majorGroupList)) {
            for (let j = 0; j < phase.majorGroupList.length; j++) {
                const group = phase.majorGroupList[j] // 获取一个子项

                // 子项的金额
                let groupMoney = AllMajorMoney * group.majorGroupQuota * 0.01

                let majorRatio = 0; // 算出这个子项下，所有专业的占比和
                if (Array.isArray(group.list)) {
                    for (let x = 0; x < group.list.length; x++) {
                        const item = group.list[x]
                        majorRatio += item.allocateQuota
                    }
                }

                if (majorRatio === 0) {
                    message = `请配置${phase.phaseName}阶段-${group.majorGroup}下的专业！`
                    break checkLoop;
                }

                console.log('各个专业比例和：', majorRatio)
                if (Math.abs(100 - majorRatio) > 0.000001) {
                    message = `${phase.phaseName}阶段-${group.majorGroup}-各个专业金额占比不正确！`
                    break checkLoop;
                }

                // 获取这个子项下，每个专业下的人员的金额，加起来是否等于这个专业的钱
                if (Array.isArray(group.list)) {
                    for (let x = 0; x < group.list.length; x++) {
                        const item = group.list[x]

                        // 校验这个专业下的人是否选了，角色是否选了
                        if (!hasEmployee(item.staffList)) {
                            message = `${phase.phaseName}阶段-${group.majorGroup}-参与分配人员未选择！`
                            break checkLoop;
                        }

                        // 这个专业的钱
                        let itemMoney = groupMoney * item.allocateQuota * 0.01

                        // 这个专业的成本，如果有技术3的分包关联了这个专业，那么需要算出它的成本
                        let cost = 0;
                        const sub = phase.subCostQuotaList.find(
                            (co) => co.major === item.type
                        );
                        if (sub) {
                            // 这项专业分包的金额 * 这项专业在这个阶段下比重 * 这个子项在这个阶段下的比重（因为可能出现多个子项，都有这个专业）
                            cost = sub.planAmount * sub.quota * 0.01 * group.majorGroupQuota * 0.01;
                        }

                        // 将所有的角色过滤出来，每种角色下的人员金额需要符合要求
                        const msg = validateRoleMoney(itemMoney - cost, item.staffList)

                        if (msg) {
                            message = `${phase.phaseName}阶段-${group.majorGroup}-${msg}-参与分配人员总金额不正确！`
                            break checkLoop;
                        }


                    }
                }

            }
        }

        // 其他下人是不是全的
        for (let j = 0; j < phase.otherList.length; j++) {
            const other = phase.otherList[j];
            const otherMoney = AllMajorMoney * other.allocateQuota * 0.01
            for (let x = 0; x < other.staffList.length; x++) {
                let staff = other.staffList[x];
                if (!staff.userId) {
                    message = `${phase.phaseName}阶段-施工配合或其他-参与分配人员未选择！`
                    break checkLoop;
                }
            }

            const money = getStaffMoney(otherMoney, other.staffList)

            // if (money !== otherMoney) {
            if (Math.abs(otherMoney - money) > 0.01) {
                message = `${phase.phaseName}阶段-施工配合或其他-参与分配人员总金额不正确！`
                break checkLoop;
            }

        }

    }


    return message;
}