<template>
  <a-tabs
    style="width: 100%"
    tabPosition="left"
    :defaultActiveKey="defaultActiveKey"
  >
    <a-tab-pane v-for="(item, index) in list" :key="item.id">
      <div slot="tab">
        <a-tooltip
          placement="top"
          :title="item.configMessage + '无法修改'"
          v-if="!item.configurable"
        >
          <div>
            <span>{{ item.phaseName }}</span>
          </div>
        </a-tooltip>

        <div v-else>
          <span>{{ item.phaseName }}</span>
        </div>
      </div>

      <Phase :index="index" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import Phase from "./phase.vue";
import { mapState } from "vuex";
export default {
  components: {
    Phase,
  },

  computed: {
    ...mapState("adjustAllocate", ["list"]),
    defaultActiveKey() {
      if (this.list.length > 0) {
        let phase;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].configurable) {
            phase = this.list[i];
            break;
          }
        }
        if (phase) {
          return phase.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
};
</script>